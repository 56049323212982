import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Homepage.css';
import { Analytics } from "@vercel/analytics/react"; // Add this line


function Homepage() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('Scribe');
  const [triggerPulse, setTriggerPulse] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Example breakpoint


  useEffect(() => {
    if (activeTab === 'Learn') {
      document.body.style.background = 'black';
    } else if (activeTab === 'Q+A') {
      document.body.style.background = 'grey';
    } else {
      document.body.style.background = 'rgba(40, 154, 166, 0.7)';
    }
    return () => {
      document.body.style.background = '';
    };
  }, [activeTab]);

  const navigateToLogin = () => {
    navigate('/login');
  };

  const TabContent = () => {
    const initialIsMobile = window.innerWidth < 768;
    const learnImages = ['/assets/home.png', '/assets/home1.png', '/assets/home2.png'];
    const learnImagesMobile = ['/assets/home_half.png', '/assets/home1_half.png', '/assets/home2_half.png'];
    const scribeImages = ['/assets/scribe1.png', '/assets/scribe2.png', '/assets/scribe3.png', '/assets/scribe4repeat.png', '/assets/scribe4.png'];
    const scribeImagesMobile = ['/assets/scribe1_half.png', '/assets/scribe2_half.png', '/assets/scribe3_half.png', '/assets/scribe4repeat_half.png'];
    const qaImages = ['/assets/qa1.png', '/assets/qa2.png'];
    const qaImagesMobile = ['/assets/qa1_half.png', '/assets/qa2_half.png'];
    const learnCaptions = ['Impactful articles. Summarised. Filtered for you.', 'All medical and surgical specialities supported.', 'One tap full-text using institutional access.'];
    const scribeCaptions = [
      'Stop writing notes. Record the interaction.', 
      '<span style="font-family: Orbitron;">medr</span> automatically generates the note. Print / Copy + Paste via <a href="/login" style="color: white; font-size: 20px; font-weight: bold;">web portal</a>.',       '<span style="font-family: Orbitron;">medr</span> suggests diagnoses and insights based on international guidelines and literature.', 
      'Read literature relevant to the patient in front of you.', 
      ''
    ];    const qaCaptions = ['Save hours sifting through articles for answers.', 'Credible answers based on credible sources.'];
    const initialImages = initialIsMobile ? learnImagesMobile : learnImages; // Adjust based on your default activeTab
    const initialCaptions = learnCaptions; // Adjust based on your default activeTab

    // Initialize state with calculated initial values
    const [currentImage, setCurrentImage] = useState(initialImages[0]);
    const [currentCaption, setCurrentCaption] = useState(initialCaptions[0]);
    const [isLoweringCompleted, setIsLoweringCompleted] = useState(false);
    const [captionKey, setCaptionKey] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Update based on your mobile breakpoint
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => window.removeEventListener('resize', handleResize);
  }, []);


    useEffect(() => {
      setCaptionKey(prevKey => prevKey + 1);
    }, [currentCaption]);

    useEffect(() => {
        let images = [];
        let captions = [];
        switch (activeTab) {
          case 'Scribe':
            images = isMobile ? scribeImagesMobile : scribeImages;
            captions = scribeCaptions;
            break;
          case 'Q+A':
            images = isMobile ? qaImagesMobile : qaImages;
            captions = qaCaptions;
            break;
          default:
            images = isMobile ? learnImagesMobile : learnImages;
            captions = learnCaptions;
            break;
        }
      
        let imageIndex = 0;
        setCurrentImage(images[imageIndex]);
        setCurrentCaption(captions[imageIndex]);
      
        const intervalId = setInterval(() => {
          imageIndex = (imageIndex + 1) % images.length;
          if (activeTab === 'Scribe' && images[imageIndex] === '/assets/scribe4.png') {
            setCurrentImage(images[imageIndex]);
            setCurrentCaption(captions[imageIndex]);
            if (imageIndex + 1 < images.length) {
              setIsLoweringCompleted(false);
              setTimeout(() => {
                setCurrentImage(images[imageIndex + 1]);
                setCurrentCaption(captions[imageIndex + 1]);
              }, 2000);
            }
          } else {
            setCurrentImage(images[imageIndex]);
            setCurrentCaption(captions[imageIndex]);
          }
        }, 5000);
      
        return () => clearInterval(intervalId);
      }, [activeTab, isMobile]);
  
      return (
        <div className="tab-content" style={{ position: 'relative', width: '100%', marginTop: isMobile ? '20%' : '0%', marginBottom: isMobile ? '-200%' : '0%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {activeTab === 'Scribe' && currentImage === '/assets/scribe4.png' ? (
            <>
              <div className="image-container" style={{ position: 'relative', maxWidth:  '40%', textAlign: 'center' }}>
                {/* scribe4.png with lowering animation */}
                <img src="/assets/scribe4.png" alt="Scribe 4" className="main-image" style={{ width: '100%', height: 'auto', marginTop: isMobile ? '20%' : '0', paddingTop: 20, animation: isMobile ? 'fadeOut 1s forwards' : 'lower 1s forwards', zIndex: 2}} onAnimationEnd={() => setIsLoweringCompleted(true)} />
                {/* scribe5.png with fade-in and scale effect */}
                <img src="/assets/scribe5.png" alt="Scribe 5" className="main-image fadeInScale" onAnimationEnd={() => { setIsLoweringCompleted(true); setTriggerPulse(true); }} />
              </div>
            </>
          ) : (
            <div className="image-container" style={{ maxWidth: '40%', textAlign: 'center', scale: isMobile ? '2.0' : '1.0' }}>
              <img src={currentImage} alt="Active tab visual" className="main-image" style={{ width: currentImage === '/assets/scribe5.png' ? '120%' : '100%', height: 'auto', paddingTop: 20 }} />
            </div>
          )}
          {(activeTab === 'Q+A' || activeTab === 'Scribe' || activeTab === 'Learn') && <QATextBox />}
          <div className="caption-container" key={captionKey} style={{
        marginTop: isMobile ? '75%' : '0', 
        textAlign: isMobile ? 'center' : 'left', // Center align text on mobile
        width: isMobile ? '80%' : '260px', // Adjust width for mobile
        position: isMobile ? 'absolute' : 'absolute',
        right: isMobile ? '20' : '100px',
        top: isMobile ? '5%' : '25%',
        marginBottom: isMobile? '20px' : '0',
        paddingLeft: isMobile ? '0' : '-200px',
        color: 'white',
        fontFamily: 'Arial',
        fontSize: '20px',
        letterSpacing: 1.2,
        overflow: 'hidden',
        animation: isMobile ? 'fadeIn 1s ease-out forwards' : 'fadeInLeft 1s ease-out forwards'
      }}>
          <p dangerouslySetInnerHTML={{ __html: currentCaption }}></p>

      </div>
        </div>
      );
  };

  const QATextBox = () => {
    const [question, setQuestion] = useState('');
    const [answer, setAnswer] = useState('');
    const [sources, setSources] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // State to track loading status

    const handleQuestionChange = (event) => {
      setQuestion(event.target.value);
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsLoading(true); // Set loading to true when the request starts
      try {
        const response = await fetch('https://api.medr.ai/query', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ question }),
        });
        const data = await response.json();
        setAnswer(data.Answer);
        setSources(data.Sources);
      } catch (error) {
        console.error('Error:', error);
      }
      setIsLoading(false); // Set loading to false when the request completes
    };

    const getSourceUrl = (filename) => {
      let displayText = filename.split('/').pop().split('.')[0].replace(/-/g, ' ');
      if (displayText.includes('pdf')) {
        displayText = displayText.split('pdf')[0];
      }
      displayText = displayText.charAt(0).toUpperCase() + displayText.slice(1);
      let iconUrl = '';

      if (filename.startsWith('medsafePDFs')) {
        iconUrl = 'https://firebasestorage.googleapis.com/v0/b/resurg-62b32.appspot.com/o/medsafe.png?alt=media&token=08db8ee6-55d4-46e9-ba08-61850a488479';
        const drugName = filename.split('/')[1].split('.')[0];
        return {
          url: `https://www.medsafe.govt.nz/profs/datasheet/${drugName.charAt(0)}/${filename.split('/')[1]}`,
          text: displayText,
          icon: iconUrl
        };
      } else if (filename.startsWith('nicePDFs')) {
        iconUrl = 'https://firebasestorage.googleapis.com/v0/b/resurg-62b32.appspot.com/o/nice.png?alt=media&token=0e7437c3-1b2a-4f5b-84e3-f62a5c18127f';
        const searchTerm = filename.split('-').slice(3).join(' ');
        return {
          url: `https://www.nice.org.uk/search?q=${encodeURIComponent(searchTerm)}`,
          text: displayText,
          icon: iconUrl
        };
      } else if (filename.startsWith('https://bpac.org.nz')) {
        iconUrl = 'https://firebasestorage.googleapis.com/v0/b/resurg-62b32.appspot.com/o/bpac3.png?alt=media&token=e002c6c4-f5b5-4f15-9b58-24b8bec9d25d';
        displayText = filename.split('/')[filename.split('/').length - 1].split('.')[0];
        return {
          url: filename,
          text: displayText,
          icon: iconUrl
        };
      } else {
        iconUrl = 'https://firebasestorage.googleapis.com/v0/b/resurg-62b32.appspot.com/o/pubmed.png?alt=media&token=97def8cc-73d8-4d08-873b-a810be4d9a82';
        const searchTerm = filename.replace(/\s/g, '+');
        return {
          url: `https://pubmed.ncbi.nlm.nih.gov/?term=${searchTerm}`,
          text: filename,
          icon: iconUrl
        };
      }
    };
  
    return (
      <div className="qa-text-box">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={question}
            onChange={handleQuestionChange}
            placeholder="Enter a medical question"
          />
          <button type="submit" disabled={isLoading}>
          {isLoading ? (
            <svg className="spinner" viewBox="0 0 50 50">
              <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
            </svg>
          ) : 'Ask'}
        </button>
        </form>
        {answer && (
          <div className="qa-answer">
            <p>{answer}</p>
            <div>
              {sources.map((source, index) => {
                const { url, text, icon } = getSourceUrl(source);
                return (
                  <p key={index}>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      {icon && <img src={icon} alt="Source icon" style={{ width: '16px', verticalAlign: 'middle', marginRight: '5px' }} />}
                      {text}
                    </a>
                  </p>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };
  
  
  const heroHeadline = () => {
    switch (activeTab) {
      case 'Scribe':
        return 'Medical Assistant';
      case 'Q+A':
        return 'Research Assistant';
      default:
        return 'Learning Companion';
    }
  };

  
  
  const typewriterClass = () => {

    if (isMobile) return '';

    switch (activeTab) {
      case 'Learn':
        return 'typewriter-text typewriter-learn';
      case 'Scribe':
        return 'typewriter-text typewriter-scribe';
      case 'Q+A':
        return 'typewriter-text typewriter-qa';
      default:
        return 'typewriter-text';
    }
  };

  const buttonStyle = {
    fontFamily: 'arial',
    fontSize: '20px',
    display: 'block', // Make buttons stack vertically
    width: '100%', // Full width of the container
    padding: '15px', // Add some padding
    border: 'none', // Remove border
    backgroundColor: 'white', // Slightly off-white background for elevation effect
    textAlign: 'left', // Align text to the left
    color: 'rgba(40, 154, 166, 1.0)', // Button text color
    cursor: 'pointer', // Change cursor on hover
    marginTop: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.4)', // Add shadow to create elevated effect
    transition: 'transform 0.3s ease', // Smooth transition for click effect
    ':hover': {
      transform: 'translateY(-2px)', // Slightly raise button on hover
    },
    ':active': {
      transform: 'translateY(2px)', // Press down button on click
    },
    borderRadius: '20px',
    opacity: 0.9,
    marginLeft: '30px',
  };
  
  return (
    <div>
      <div className="homepage-container">
        <div className="typewriter">
          <h1 style={{ marginTop: '20px' }}>
            <strong>medr</strong> <span style={{ fontWeight: 'lighter', fontFamily: 'Arial' }}>| {activeTab}</span>
            <div className="hamburger-menu" onClick={() => setIsMenuOpen(!isMenuOpen)} style={{ position: 'absolute', top: 0, right: 0, padding: '20px' }}>
  &#9776;
</div>
{isMenuOpen && (
  <div className={`menu-dropdown ${isMenuOpen ? 'show' : ''}`} style={{paddingTop: '35px', paddingLeft: '10px', borderRadius: '20px 0 0 0',width: '100%', position: 'fixed', top: 0, right: isMenuOpen ? '0' : '-70%', height: '100%', backgroundColor: 'rgba(40, 154, 166, 0.8)', transition: 'right 0.3s', zIndex: '2000'}}>
<button onClick={() => setIsMenuOpen(false)} className="close-btn" aria-label="Close menu" style={{color: 'white', fontSize: '40px'}}>×</button>    
<button onClick={() => navigate('/pricing')} style={{...buttonStyle, backgroundColor: 'white'}}>Pricing</button>
<button onClick={() => navigate('/start')} style={{...buttonStyle, backgroundColor: 'white'}}>How It Works</button>
    <button onClick={() => navigate('/security')} style={buttonStyle}>Security</button>
    <button onClick={navigateToLogin} style={buttonStyle}>Login</button>
  </div>
)}
          </h1>
          
          <div className="navbar">
            <button onClick={() => setActiveTab('Learn')} 
              style={{ fontWeight: activeTab === 'Learn' ? 'bold' : 'normal' }}
            >
              Learn
            </button>
            <button 
              onClick={() => setActiveTab('Scribe')} 
              style={{ fontWeight: activeTab === 'Scribe' ? 'bold' : 'normal' }}
            >
              Scribe
            </button>
            <button 
              onClick={() => setActiveTab('Q+A')} 
              style={{ fontWeight: activeTab === 'Q+A' ? 'bold' : 'normal' }}
            >
              Q+A
            </button>
          </div>
        </div>
        <button 
  onClick={() => navigate('/pricing')}
  style={{
    position: 'absolute',
    top: 0,
    right: 0,
    marginTop: '20px',
    marginRight: '350px',
    border: '2px solid white',
    color: 'white',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    paddingRight: 15,
    paddingLeft: 15,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: '10px',
    fontWeight: 'bold',
    fontSize: 13
  }}
>
  Pricing
</button>
        <button 
  onClick={() => navigate('/start')}
  style={{
    position: 'absolute',
    top: 0,
    right: 0,
    marginTop: '20px',
    marginRight: '220px',
    border: '2px solid white',
    color: 'white',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    paddingRight: 15,
    paddingLeft: 15,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: '10px',
    fontWeight: 'bold',
    fontSize: 13,
    animation: triggerPulse ? 'pulse 2s infinite' : 'none'
  }}
>
  How It Works
</button>
<button 
  onClick={() => navigate('/security')}
  style={{
    position: 'absolute',
    top: 0,
    right: 0,
    marginTop: '20px',
    marginRight: '120px',
    border: '2px solid white',
    color: 'white',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    paddingRight: 15,
    paddingLeft: 15,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: '10px',
    fontWeight: 'bold',
    fontSize: 13
  }}
>
  Security
</button>
<button 
  onClick={navigateToLogin}
  style={{
    position: 'absolute',
    top: 0,
    right: 0,
    marginTop: '20px',
    marginRight: '40px', // This remains for the Login button
    border: '2px solid white',
    color: 'rgba(40, 154, 166, 1.0)',
    backgroundColor: 'white',
    cursor: 'pointer',
    paddingRight: 15,
    paddingLeft: 15,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: '10px',
    fontWeight: 'bold',
    fontSize: 13,
    

  }}
>
  Login
</button>

<div className="hero-section">
<img src="/assets/medr_qr.png" alt="medr QR Code" className="desktop-only-image" style={{ width: '128px', height: '128px' }} />
<div style={{ opacity: 0 }}> br</div>
  <div className="hero-headline">
    The <span className="highlighted-word">Ultimate</span><br/><span className={typewriterClass()}>{heroHeadline()}</span>
  </div>
  <div className="hero-subheading">
    <span style={{ fontFamily: 'Orbitron', fontWeight: 'bold', letterSpacing: 4 }}>medr</span> reduces your clinical admin, whilst providing the most tailored medical learning experience.
  </div>
  <button 
  onClick={() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Check for iOS devices
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = 'https://apps.apple.com/us/app/medr/id1585927466?uo=4'; // Replace 'YOUR_APP_ID' with your actual App Store ID
    } 
    // Check for Android devices
    else if (/android/i.test(userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.kaveshan.medr&hl=en_AU&gl=US&pli=1'; // Replace 'YOUR_PACKAGE_NAME' with your actual package name
    }
  }}
  className="get-started-mobile"
>
  Download
</button>
  <div class="mobile-text-container">
  This is additional information visible on mobile only.
</div>
<div class="mobile-text-container1">
  This is additional information visible on mobile only.
</div>
  
</div>
      <TabContent />
    </div>
    <Analytics />
    
  </div>
);

}

export default Homepage;