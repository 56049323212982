// src/components/SecurityPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Homepage.css';
import { FaChevronDown } from 'react-icons/fa';

const ImageCaption = ({ title, image, captions, index, imgWidth = '50%' }) => {
  const [activeTab, setActiveTab] = useState(null);

  return (
    <div style={{ marginBottom: index === 7 ? '0px' : '20px', width: '100%', marginTop: index === 0 ? '40px' : '0' // Add 20px top margin only for the first tile
  }}> {/* Ensure the outer div takes full width */}
      <div
        onClick={() => setActiveTab(activeTab === index ? null : index)}
        style={{
          cursor: 'pointer',
          backgroundColor: 'white',
          borderRadius: '10px',
          padding: '15px',
          marginLeft: '7.5%',
          marginTop: '1%',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '80%' // Set a fixed width for all tabs
        }}
      >
        <h3 style={{ color: 'black', margin: 0 }}>{title}</h3>
        <FaChevronDown
          style={{
            color: 'black',
            transform: activeTab === index ? 'rotate(180deg)' : 'none',
            transition: 'transform 0.3s',
          }}
        />
      </div>
      {activeTab === index && (
        <div style={{ width: '100%' }}> {/* Ensure the content also takes full width */}
          <img src={image} alt={title} style={{ width: imgWidth, marginTop: '20px' }} />
          <div style={{ width: '50%', color: 'black', fontFamily: 'Arial', fontSize: '14px', textAlign: 'justify', marginLeft: 'auto', marginRight: 'auto' }}>
            {captions.map((caption, idx) => (
              <div key={idx} style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                <div style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%',
                  backgroundColor: 'black',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexShrink: 0,
                  marginRight: '10px'
                }}>{idx + 1}</div>
                <p>{caption}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

function StartPage() {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.background = 'rgba(40, 154, 166, 0.7)';
    return () => {
      document.body.style.background = '';
    };
  }, []);

  return (
    <div>
      <button onClick={() => navigate('/')} style={{
        cursor: 'pointer',
        position: 'absolute',
        top: '20px',
        left: '20px',
        fontSize: '16px',
        fontWeight: 'bold',
        padding: '10px',
        backgroundColor: '#f1f1f1',
        color: 'rgba(40, 154, 166, 0.7)',
        border: 'none',
        borderRadius: '5px',
        zIndex: 1000,
      }}>
        {'<'}
      </button>
      <div className="typewriter">
        <h1 style={{ marginTop: '20px', color: 'white' }}>
          <strong>medr</strong><span style={{ fontWeight: 'lighter', fontFamily: 'Arial' }}>  <span style={{color: 'white', fontWeight: 'bold'}}>Start</span></span>
        </h1>
      </div>
      <div className="SP-div-style">
        <ImageCaption
          title="How to AI Scribe"
          image={require('../assets/scribe_anno.png')}
          captions={[
            'Select First Presentation or Follow Up to generate the most appropriate clinical summary.',
            'Start recording as you enter a patient interaction. The interaction will be transcribed on-device (without sending any audio to a server). Stop recording and the transcription will go to our private server for conversion to a clinical summary.',
            'Toggle Cloud functionality to view / edit / print your clinical summaries via the web portal. If you turn this off all patient files will be removed from the cloud but still accessible from the mobile device.',
            'Tap on the generated patient file to see clinical summary, diagnoses, insights, and suggested articles.'
          ]}
          index={0}
        />
        <ImageCaption
          title="Access patient files via web portal"
          image={require('../assets/portal_anno.png')}
          captions={[
            'Edit the generated summary on device or on the web portal.',
            'Generate a PDF summary to print and add to patient notes.'
          ]}
          index={1}
          imgWidth="95%"
        />
        <ImageCaption
          title="MEDR can suggest diagnoses and treatments"
          image={require('../assets/diagnoses_anno.png')}
          captions={[
            'Differential diagnoses generated from our AI model.',
            'Insights for each differential diagnosis based on literature (PubMed), guidelines (NICE UK, BPAC NZ, medsafe).'
          ]}
          index={2}
        />
        <ImageCaption
          title="Read literature based on your patients diagnoses"
          image={require('../assets/suggested_anno.png')}
          captions={[
            'Suggested articles directly related to the patients likely diagnoses.'
          ]}
          index={3}
        />
        <ImageCaption
          title="Ask MEDR Medical Questions"
          image={require('../assets/qa_anno.png')}
          captions={[
            'medr will research your clinical questions and provide answers from credible sources.'
          ]}
          index={4}
        />
        <ImageCaption
          title="Get credible answers with references"
          image={require('../assets/qa2_anno.png')}
          captions={[
            'Read relevant guidelines and articles to enhance your learning and corroborate decision making.'
          ]}
          index={5}
        />
        <ImageCaption
          title="Stay up to date with medical literature"
          image={require('../assets/home_anno.png')}
          captions={[
            'Select your specialty',
            'Add your institutional login for full text access.',
            'Select your subspecialty.',
            'Save articles to your profile to read later.',
            'Tap to read the article.'
          ]}
          index={6}
        />
      </div>
    </div>
  );
}

export default StartPage;
