import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyByu8C_wPSPeiBPEcmo0yLGJ8_zOMZZ5LA",
    authDomain: "resurg-62b32.firebaseapp.com",
    projectId: "resurg-62b32",
    storageBucket: "resurg-62b32.appspot.com",
    messagingSenderId: "718564531329",
    appId: "1:718564531329:web:99874785d540a090c00180",
    measurementId: "G-V2EXFD39MD"
  };
  

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

export { app, auth, firestore }; // Export this