// src/components/SecurityPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Step 1: Import useNavigate
import './Homepage.css'; // Assuming the styles are defined here
import cloudOff from '../assets/cloud_off.png';
import arrowWithHeads from '../assets/arrow_with_heads.png';
import iphone from '../assets/iphone.png';
import server from '../assets/server.png';

function SecurityPage() {
  const navigate = useNavigate();
  useEffect(() => {
    document.body.style.background = 'rgba(40, 154, 166, 0.7)';
    return () => {
      document.body.style.background = '';
    };
  }, []);

  return (
    <div>
      <button onClick={() => navigate('/')} style={{
        cursor: 'pointer',
        position: 'absolute',
        top: '20px',
        left: '20px',
        fontSize: '16px',
        fontWeight: 'bold',
        padding: '10px',
        backgroundColor: '#f1f1f1',
        color: 'rgba(40, 154, 166, 0.7)',
        border: 'none',
        borderRadius: '5px',
        zIndex: 1000, // Make sure it's above other elements
      }}>
        {'<'} {/* This represents the left arrow */}
      </button>
      <div className="typewriter">
        
        <h1 style={{ marginTop: '20px' }}>
          
          <strong>medr</strong><span style={{ fontWeight: 'lighter', fontFamily: 'Arial' }}>Security</span>
        </h1>
        
      </div>
      <div className="SP1-div-style">
        {/* Adjusted marginLeft for the cloud icon and text container */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '0px' }}> 
          <img src={cloudOff} alt="Cloud Off" style={{ scale: '50%', marginBottom: '5px 0', marginTop: '0px'}}/>
          <div className="security-info">
  Patient file can be stored locally (on-device only), or the cloud option may be toggled to send the file to the cloud (Cloud Firestore – HIPAA compliant), so that the user can edit the file in a web browser. At any point, all patient files can be taken off the cloud server by toggling the cloud icon on the Scribe screen. 

  In the event that there is a breach of security and a patient file has been accessed by a user other than whom created it, our team and all parties involved will be notified. 
</div>
        </div>
        <img src={arrowWithHeads} alt="Arrow with Heads" style={{scale: '25%', margin: '-230px 0'}} />
        <div className="flex-center">
  <img src={iphone} alt="iPhone" className="iphone-image"/>
  <div className="text-container">
    Interaction transcribed ‘on-device’ - no recordings of the interaction are sent over the internet.
  </div>
</div>
        <img src={arrowWithHeads} alt="Arrow with Heads" style={{ scale: '25%', margin: '-230px 0'}} />
        <div className="flex-center">
          <img src={server} alt="Server" className="iphone-image"/>
          <div className="text-container">
          Secure, on-site server. Clinical summary, differential diagnoses and insights are produced and returned to the user. No data is stored on this server. 
          </div>
        </div>
        <div style={{ fontFamily: 'Arial', fontSize: '18px', color: 'black', background: 'transparent', textAlign: 'justify', marginBotton: '70px', marginLeft: '70px', marginRight: '70px', marginTop: '-70px'}}>
        Note: Please check with your Clinic / Institution / Hospital to ensure the above handling of data fits your security guidelines and feel free to email kaveshan.naidoo@medr.ai if there are any concerns or anything else we could do to improve privacy and security. 
        </div>
      </div>
    </div>
  );
}

export default SecurityPage;