import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Input, Button, Card } from 'antd';
import { doc, getDoc } from 'firebase/firestore';
import { auth, firestore } from './firebase'; // Ensure this path is correct
import ios from '../assets/ios.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const uid = userCredential.user.uid;
      const docRef = doc(firestore, "users", uid); // Direct use of firestore instance
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        navigate('/profile');
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error signing in with email and password", error);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
      <img src="https://firebasestorage.googleapis.com/v0/b/resurg-62b32.appspot.com/o/logo_blue.png?alt=media&token=a00fbd88-3708-45ad-b037-df88343b53bf" style={{ width: '300px', marginBottom: '10px', marginLeft: '5px' }} alt="Logo" />
      <Card style={{ width: 300, borderRadius: '15px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)' }}>
        <Input placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <Input.Password placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} style={{ marginTop: '10px' }}/>
        <Button type="primary" onClick={handleLogin} style={{ marginTop: '20px', backgroundColor: '#289aa6', borderColor: '#289aa6' }}>Login</Button>
      </Card>
      <p style={{color: 'grey', fontSize: '14px', marginBottom: '-10px', marginTop: '40px'}}>Download / Register: </p>
      <div style={{ opacity: 0 }}> br</div>

      <img src="/assets/medr_qr.png" alt="medr QR Code" className="desktop-only-image" style={{ width: '128px', height: '128px' }} />

    </div>
  );
};

export default Login;
