import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Homepage.css';
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

function PricingPage() {
    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.background = 'rgba(40, 154, 166, 0.7)';
        return () => {
            document.body.style.background = '';
        };
    }, []);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
            <button onClick={() => navigate('/')} style={{
                cursor: 'pointer',
                position: 'absolute',
                top: '20px',
                left: '20px',
                fontSize: '16px',
                fontWeight: 'bold',
                padding: '10px',
                backgroundColor: '#f1f1f1',
                color: 'rgba(40, 154, 166, 0.7)',
                border: 'none',
                borderRadius: '5px',
                zIndex: 1000,
            }}>
                {'<'}
            </button>
            <h1 className="pricing-title">
    <strong className="pricing-title-brand">medr </strong>
    <span className="pricing-title-text">Pricing</span>
</h1>
            <div className="pricing-container" style={{ display: 'flex', gap: '20px', flexDirection: 'row' }}>
                {/* Free Plan */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'white',
                    borderRadius: '10px',
                    padding: '20px',
                    width: '200px',
                    textAlign: 'center',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                }}>
                    <h2 style={{ fontSize: '24px', color: 'black' }}>Starter</h2>
                    <p style={{ fontSize: '18px', color: 'black' }}>Free</p>
                    <ul style={{ listStyleType: 'none', padding: '0' }}>
                        <li style={{ margin: '10px 0', color: 'black' }}>Top 10 Articles</li>
                        <li style={{ margin: '10px 0', color: 'black' }}>5 Medical Q+A</li>
                        <li style={{ margin: '10px 0', color: 'orange' }}>✗ AI Scribe</li>
                        <li style={{ margin: '10px 0', color: 'orange' }}>✗ Diagnoses</li>
                        <li style={{ margin: '10px 0', color: 'orange' }}>✗ Insights</li>
                    </ul>
                </div>

                {/* Full Pack */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'black',
                    borderRadius: '10px',
                    padding: '20px',
                    width: '200px',
                    textAlign: 'center',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                }}>
                    <h2 style={{ fontSize: '24px', color: 'white' }}>Pro</h2>

                    <p style={{ fontSize: '12px', color: 'white', marginBottom: '-10px', fontStyle: 'italic' }}>7 days free then</p>
                    <p style={{ fontSize: '18px', color: 'rgb(170, 255, 0)' }}>$5 per month</p>
                    <ul style={{ listStyleType: 'none', padding: '0', color: 'white' }}>
                        <li style={{ margin: '10px 0', color: 'white' }}>Unlimited Articles</li>
                        <li style={{ margin: '10px 0', color: 'white' }}>Unlimited Medical Q+A</li>
                        <li style={{ margin: '10px 0', color: 'white' }}>Unlimited AI Scribe</li>
                        <li style={{ margin: '10px 0', color: 'white' }}>Unlimited Diagnoses</li>
                        <li style={{ margin: '10px 0', color: 'white' }}>Unlimited Insights</li>
                    </ul>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px', position: 'relative' }}>
    {isMobile ? (
        <button
            onClick={() => {
                const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                // Check for iOS devices
                if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                    window.location.href = 'https://apps.apple.com/us/app/medr/id1585927466?uo=4';
                }
                // Check for Android devices
                else if (/android/i.test(userAgent)) {
                    window.location.href = 'https://play.google.com/store/apps/details?id=com.kaveshan.medr&hl=en_AU&gl=US&pli=1';
                }
            }}
            className="get-started-mobile"
        >
            Download
        </button>
    ) : (
        <div style={{ position: 'relative', width: '128px', height: '128px' }}>
            <img src="/assets/medr_qr.png" alt="QR Code" style={{ width: '100%', height: '100%' }} />
        </div>
    )}
</div>
        </div>
    );
}

export default PricingPage;
