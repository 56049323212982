import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import Profile from './components/Profile';
import Homepage from './components/Homepage'; // Import the Homepage component\
import SecurityPage from './components/SecurityPage';
import StartPage from './components/StartPage';
import PricingPage from './components/Pricing';


function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Homepage />} /> {/* Set Homepage as the default route */}
          <Route path="/login" element={<Login />} /> {/* Change this to /login */}
          <Route path="/profile" element={<Profile />} />
          <Route path="/security" element={<SecurityPage />} />
          <Route path="/start" element={<StartPage />} />
          <Route path="/pricing" element={<PricingPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;