import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, getDocs, updateDoc } from 'firebase/firestore';
import { auth, firestore } from './firebase';
import './Profile.css'; // Make sure to create this CSS file
import '@fortawesome/fontawesome-free/css/all.css';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [patientFiles, setPatientFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editText, setEditText] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState('');
  const [isEditingName, setIsEditingName] = useState(false);
  const [authInitialized, setAuthInitialized] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        // User is signed in, fetch user data and patient files.
        fetchUserData(user.uid); // Pass uid directly from the user object
      } else {
        // User is signed out or auth state is not yet initialized.
        setUserData(null);
        setPatientFiles([]);
      }
      setAuthInitialized(true); // Auth state is now initialized
    });

    return () => unsubscribe(); // Cleanup the observer on component unmount
  }, []);

  const fetchUserData = async (uid) => {
    // Use the uid parameter instead of accessing auth.currentUser directly
    const userDocRef = doc(firestore, "users", uid);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      setUserData(userDocSnap.data());
      await fetchPatientFiles(uid); // Fetch patient files for the authenticated user
    } else {
      console.log("No such document!");
    }
  };

  const fetchPatientFiles = async (userId) => {
    const filesRef = collection(firestore, "users", userId, "files");
    const querySnapshot = await getDocs(filesRef);
    const files = querySnapshot.docs.map(doc => {
      const fileData = doc.data();
      return {
        id: doc.id,
        name: fileData.name,
        text: fileData.text ? atob(fileData.text) : '', // Decode the base64-encoded text
        diagnoses: fileData.diagnoses ? atob(fileData.diagnoses) : '', // Decode the base64-encoded diagnoses
        insights: fileData.insights ? atob(fileData.insights) : '', // Decode the base64-encoded insights
      };
    });
    setPatientFiles(files);
  };

  useEffect(() => {
    if (selectedFile) {
      // Use safeBase64Decode to decode the text
      const decodedText = safeBase64Decode(selectedFile.text);
      setEditText(decodedText);

      updateAccessedBy(selectedFile.id);
    }
  }, [selectedFile]);

  const updateAccessedBy = async (fileId) => {
    const fileRef = doc(firestore, "users", auth.currentUser.uid, "files", fileId);
    try {
      await updateDoc(fileRef, { accessedBy: auth.currentUser.uid });
      console.log("File access updated successfully");
    } catch (error) {
      console.error("Error updating file access: ", error);
    }
  };

  const safeBase64Decode = (encodedString) => {
    try {
      return atob(encodedString);
    } catch (e) {
      console.error('Failed to decode base64 string:', e);
      // Return the original encoded string to show the problem in the UI
      return encodedString;
    }
  };

  const getSourceUrl = (filename) => {
    let displayText = filename.split('/').pop().split('.')[0].replace(/-/g, ' ');
    if (displayText.includes('pdf')) {
      displayText = displayText.split('pdf')[0];
    }
    displayText = displayText.charAt(0).toUpperCase() + displayText.slice(1);
    let iconUrl = '';

    if (filename.startsWith('medsafePDFs')) {
      iconUrl = 'https://firebasestorage.googleapis.com/v0/b/resurg-62b32.appspot.com/o/medsafe.png?alt=media&token=08db8ee6-55d4-46e9-ba08-61850a488479';
      const drugName = filename.split('/')[1].split('.')[0];
      return {
        url: `https://www.medsafe.govt.nz/profs/datasheet/${drugName.charAt(0)}/${filename.split('/')[1]}`,
        text: displayText,
        icon: iconUrl
      };
    } else if (filename.startsWith('nicePDFs')) {
      iconUrl = 'https://firebasestorage.googleapis.com/v0/b/resurg-62b32.appspot.com/o/nice.png?alt=media&token=0e7437c3-1b2a-4f5b-84e3-f62a5c18127f';
      const searchTerm = filename.split('-').slice(3).join(' ');
      return {
        url: `https://www.nice.org.uk/search?q=${encodeURIComponent(searchTerm)}`,
        text: displayText,
        icon: iconUrl
      };
    } else if (filename.startsWith('https://bpac.org.nz')) {
      iconUrl = 'https://firebasestorage.googleapis.com/v0/b/resurg-62b32.appspot.com/o/bpac3.png?alt=media&token=e002c6c4-f5b5-4f15-9b58-24b8bec9d25d';
      displayText = filename.split('/')[filename.split('/').length - 1].split('.')[0];
      return {
        url: filename,
        text: displayText,
        icon: iconUrl
      };
    } else {
      // Handle other cases or add a default icon if needed
      iconUrl = 'https://firebasestorage.googleapis.com/v0/b/resurg-62b32.appspot.com/o/pubmed.png?alt=media&token=97def8cc-73d8-4d08-873b-a810be4d9a82';
      const searchTerm = filename.replace(/\s/g, '+'); // Replace all spaces with '+'
      return {
        url: `https://pubmed.ncbi.nlm.nih.gov/?term=${searchTerm}`,
        text: filename,
        icon: iconUrl // Default icon URL or leave as an empty string
      };
    }
  };

  const exportPDF = () => {
    const input = document.getElementById('pdf-content');
    input.style.display = 'block'; // Temporarily display the div

    // Get current date and time
    const now = new Date();
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    const formattedDate = now.toLocaleDateString('en-GB', dateOptions);

    // Add date to the div
    const dateElement = document.createElement('p');
    dateElement.textContent = formattedDate;
    input.appendChild(dateElement);

    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', [canvas.width * 0.2645833333, canvas.height * 0.2645833333]);
        pdf.addImage(imgData, 'PNG', 0, 0, canvas.width * 0.2645833333, canvas.height * 0.2645833333);
        pdf.save(`${selectedFile.name.split('-')[0]}.pdf`);
      })
      .finally(() => {
        input.style.display = 'none'; // Hide the div again after PDF generation
        // Remove the date element
        input.removeChild(dateElement);
      });
  };

  const handleNameBlur = async () => {
    setIsEditingName(false);
    const userRef = doc(firestore, "users", auth.currentUser.uid);
    try {
      await updateDoc(userRef, { name: userData.name });
    } catch (error) {
      console.error("Error updating name: ", error);
    }
  };

  const renderTextWithSpacingBeforeNumbers = (text) => {
    // Insert a unique marker before digits followed by a period to denote new sections.
    // The marker will help us to split the text and add space before the number.
    const updatedText = text.replace(/(\d+\.\s?)/g, '|||$1');

    // Split the text at each marker, creating an array where each element is either a section of text or a numbered item.
    const parts = updatedText.split('|||');

    // Filter out empty strings that may result from the split to avoid rendering empty elements.
    const nonEmptyParts = parts.filter(part => part.trim() !== '');

    // Map over the non-empty parts, rendering each part as a separate element.
    // Add top margin to create visual separation, but only to elements starting with a number,
    // which indicates a new section according to the original pattern.
    return nonEmptyParts.map((part, index) => (
      <div key={index} style={{ marginTop: part.match(/^\d+\.\s?/) ? '1rem' : '0' }}>
        {part}
      </div>
    ));
  };

  const handleSave = async () => {
    if (selectedFile && !isSaving) {
      setIsSaving(true);
      const fileRef = doc(firestore, "users", auth.currentUser.uid, "files", selectedFile.id);
      try {
        const encodedText = btoa(editText); // Encode the edited text to base64
        await updateDoc(fileRef, { text: encodedText });

        // Update the patientFiles state to reflect the new text
        const updatedFiles = patientFiles.map(file =>
          file.id === selectedFile.id ? { ...file, text: encodedText } : file
        );
        setPatientFiles(updatedFiles);
        setSelectedFile({ ...selectedFile, text: encodedText }); // Update selected file text
        setSaveError('');
      } catch (error) {
        console.error("Error updating file: ", error);
        setSaveError('Failed to save changes. Please try again.');
      }
      setIsSaving(false);
    }
  };

  if (!authInitialized) {
    return <div>Loading medr Scribe...</div>; // Show loading state until auth is initialized
  }

  if (!userData) {
    return <div>Loading patient list...</div>; // Show loading state until user data is fetched
  }

  return (
    <div className="profile-container">
      <aside className="sidebar container1">
        <img src="https://firebasestorage.googleapis.com/v0/b/resurg-62b32.appspot.com/o/logo_blue.png?alt=media&token=a00fbd88-3708-45ad-b037-df88343b53bf" alt="Logo" style={{ width: '170px', height: '120px' }} />
        <h3>Patients</h3>
        <ul>
          {patientFiles.map(file => (
            <li key={file.id} onClick={() => setSelectedFile(file)}>
              {file.name.split('-')[0]}
            </li>
          ))}
        </ul>
      </aside>
      <main className="content">
        <div className="user-info">
          {isEditingName ? (
            <input
              value={userData.name}
              onChange={(e) => setUserData({ ...userData, name: e.target.value })}
              onBlur={handleNameBlur}
            />
          ) : (
            <h1>{userData.name}</h1>
          )}
          <i className="fas fa-pencil-alt" onClick={() => setIsEditingName(true)}></i>
          <p className="specialty">{userData.specialty}</p>
        </div>
        {selectedFile && (
          <div className="patient-details-container">
            <div className="patient-details container">
              <h2>
                {selectedFile.name.split('-')[0]}
                <FontAwesomeIcon icon={faFilePdf} onClick={exportPDF} style={{ marginLeft: '20px', cursor: 'pointer', color: 'purple' }} />
              </h2>
              <textarea
                className="edit-textarea"
                value={editText}
                onChange={(e) => setEditText(e.target.value)}
                disabled={isSaving}
              />
              <button className="save-btn" onClick={handleSave} disabled={isSaving}>
                {isSaving ? 'Saving...' : 'Save Changes'}
              </button>
              {saveError && <p className="error">{saveError}</p>}
            </div>
            <div id="pdf-content" style={{ display: 'none', whiteSpace: 'pre-wrap', padding: '20px 60px' }}>
              <h2>{selectedFile.name.split('-')[0]}</h2>
              <p style={{ textAlign: 'left' }}>{editText}</p>
              <br />
              <br />
              <strong>
                <p>{userData.name}</p>
              </strong>
              <p>{userData.specialty}</p>
            </div>
            <div className="patient-insights">
              <div className="container2 scrollable-container1">
                <h3 className="title">Differential Dx</h3>
                {renderTextWithSpacingBeforeNumbers(selectedFile.diagnoses)}
              </div>
              <div className="container3 scrollable-container">
                <h3 className="title">Insights</h3>
                <div>
                  {selectedFile.insights.includes('Sources:') && (
                    <p>{renderTextWithSpacingBeforeNumbers(selectedFile.insights.split('Sources:')[0])}</p>
                  )}
                  <p> </p>
                  <div>
                    {selectedFile.insights.split('Sources:')[1]?.split('\n').map((line, index) => {
                      line = line.trim(); // Trim whitespace from the line
                      if (line === '') {
                        return null; // If the line is empty, don't render anything
                      }
                      const { url, text, icon } = getSourceUrl(line);
                      return (
                        <p key={index}>
                          <a href={url} target="_blank" rel="noopener noreferrer">
                            {icon && <img src={icon} alt="Source icon" style={{ width: '16px', verticalAlign: 'middle', marginRight: '5px' }} />}
                            {text}
                          </a>
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default Profile;